<template>
	<UserEventCardComponent
		v-for="update, i in status_updates"
		:key="`status-update-${i}`"
		:heading="ucFirst( update.updated_status )"
		:user="update.user && update.user.length ? update.user[0] : null"
		:event-label="getDate( update.timestamp )"
		:event-is-pending="false"
	/>
</template>

<script setup>
import UserEventCardComponent from './UserEventCardComponent';
import { defineProps, computed } from 'vue';
import { ucFirst } from '../../helpers';

import moment from 'moment';

const props = defineProps( {
	asset: Object
} );

const status_updates = computed( () => {
	return props.asset && props.asset.status_updates;
} );

const getDate = timestamp => {
	return moment( new Date( timestamp ) ).format( 'D/M/YY' );
};
</script>
